import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
    Divider,
} from '@mui/material';
import Moment from 'moment';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LineShareButton,
    LineIcon,
    LivejournalShareButton,
    LivejournalIcon
} from "react-share";
import Ad from './ad';

export default function V2Article({
    title,
    author,
    create_at,
    update_at,
    image,
    body,
    uid
}) {
    return (
        <>
            <Grid
                item
                container
                xs={12}
                sx={{ width: '100%', bgcolor: '#111111' }}
            >
                <Grid
                    container
                    item
                    xs={12}
                >
                    <img
                        src={image}
                        style={{ position: "relative", width: '100%' }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ padding: 0 }}
                // sx={{ width: '100%', bgcolor: '#393939' }}
                >
                    <Typography
                        className='section-header-white2'
                    >
                        {title}
                    </Typography>
                    <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                            'border-bottom': '4px solid #FE5907', 
                        }}
                    >
                        <Typography
                            className='fixture-short-desc'
                        >
                            โดย {author}
                        </Typography>
                        <Typography
                            className='fixture-short-desc'
                        >
                            เขียนเมื่อ {Moment(create_at).format('DD/MM/YYYY, hh:mm')} | อัพเดทล่าสุด {Moment(update_at).format('DD/MM/YYYY, hh:mm')}
                        </Typography>
                        <div>
                            <FacebookShareButton
                                style={{
                                    paddingLeft: 10
                                }}
                                url={"https://www.thaisportplus.com/news/" + uid}
                                quote={title}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton
                                style={{
                                    paddingLeft: 10
                                }}
                                url={"https://www.thaisportplus.com/news/" + uid}
                                className="Demo__some-network__share-button"
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <LineShareButton
                                style={{
                                    paddingLeft: 10
                                }}
                                url={"https://www.thaisportplus.com/news/" + uid}
                                className="Demo__some-network__share-button"
                            >
                                <LineIcon size={32} round />
                            </LineShareButton>
                            <LivejournalShareButton
                                style={{
                                    paddingLeft: 10
                                }}
                                // onClick={handleCopy()}
                                className="Demo__some-network__share-button"
                            >
                                <LivejournalIcon size={32} round />
                            </LivejournalShareButton>
                        </div>
                    </Grid>
                    <div
                        sx={{ color: "#FFFFFF" }}
                        className='article-new'
                        dangerouslySetInnerHTML={{ __html: body }}
                    >
                    </div>
                </Grid>

            </Grid>
        </>
    )
}