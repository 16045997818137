import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    Container,
} from '@mui/material';
import Header from '../components/header';
import { SuperSEO } from "react-super-seo";
import useNewsHook from '../hooks/news-hook';
import useVideoHook from '../hooks/video-hook';
import useLiveHook from '../hooks/live-hook';
import NewsDetailMDLayout from '../layouts/news-detail/news-detail-md-layout';
import NewsDetailSMLayout from '../layouts/news-detail/news-detail-sm-layout';
import NewsDetailXSLayout from '../layouts/news-detail/news-detail-xs-layout';
import HeaderApp from '../components/header-app';
import NewsDetailXSLayoutApp from '../layouts/news-detail/news-detail-xs-layout-app';
import HeaderAppBack from '../components/header-app-back';
import NewsDetailSMLayoutApp from '../layouts/news-detail/news-detail-sm-layout-app';
import V2NewsDetailSMLayoutApp from '../layouts/news-detail/v2-news-detail-sm-layout-app';
import V2NewsDetailXSLayoutApp from '../layouts/news-detail/v2-news-detail-xs-layout-app';

export default function V2AppNewsDetailsPage() {
    const { uid } = useParams();
    const { video_list } = useVideoHook()
    const { live_list } = useLiveHook()
    const { fetchNewsDetails, news_all } = useNewsHook()
    const [news_details, setNewsDetails] = useState([])

    useEffect(() => {
        const asyncFn = async () => {
            console.log("news_uid", uid);
            let res = await fetchNewsDetails(uid)
            console.log('news', res.data.data);
            setNewsDetails(res.data.data[0])
        }
        asyncFn()
    }, [])

    return (
        <>
            {/* <SuperSEO
                title="Thai Sport Plus"
                description="สถานีกีฬา 24 ชั่วโมง : ติดตามบทวิเคราะห์สัมภาษณ์นักกีฬาอย่างเจาะลึกตรงประเด็น"
                lang="en"
                openGraph={{
                    ogTitle: "Thai sport plus",
                    ogType: "article",
                    ogDescription: "สถานีกีฬา 24 ชั่วโมง : ติดตามบทวิเคราะห์สัมภาษณ์นักกีฬาอย่างเจาะลึกตรงประเด็น",
                    ogImage: {
                        ogImage: "https://thaisportplus.com/static/media/logo.d20e9b6809f1ea4b254c.png",
                        ogImageAlt: "news",
                        ogImageWidth: 1200,
                        ogImageHeight: 630,
                        ogImageType: "image/jpeg",
                    },
                }}
            /> */}
            <div
            
            >
                <Container
                    disableGutters={true}
                    maxWidth="false"
                    className='web-bg'
                    sx={{
                        display: {
                            md: 'flex',
                            xs: 'flex',
                            sm: 'flex',
                        },
                        flexDirection: 'column',
                        marginTop: -20
                    }}
                >
                    <V2NewsDetailSMLayoutApp
                        news_details={news_details}
                        news_all={news_all}
                    />
                    <V2NewsDetailXSLayoutApp
                        news_details={news_details}
                        news_all={news_all}
                    />
                </Container>

            </div>

        </>
    )
}
