import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Container,
} from '@mui/material';
import Article from '../../components/article';
import ListNews from '../../components/list/list-news';
import CardMiddle from '../../components/card/card-middle';
import CardSmall from '../../components/card/card-small';
import AdMobile from '../../components/ad-mobile';
import ad2 from '../../gpp-02.png';
import V2Article from '../../components/v2-article';

export default function V2NewsDetailXSLayoutApp({ news_details, news_all }) {
    const handleCardClick = (uid) => {
        if (uid === 0) {
            window.location = "/app/news"
        } else {
            window.location = "/app/news/" + uid
        }
    }
    return (
        <>
            <Container
                disableGutters={true}
                maxWidth="xl"
                sx={{
                    display: {
                        md: 'none',
                        xs: 'flex',
                        sm: 'none',
                    },
                    flexDirection: 'column',
                    columnGap: 2,
                    rowGap: 2,
                    pl: 2,
                    pr: 2,
                    pt: 2,
                    pb: 2
                }}
            >
                <Grid
                    direction="row"
                    container
                    spacing={2}
                    rowSpacing={2}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        {
                            news_details !== undefined ?
                                news_details.news_thumbnail !== undefined ?
                                    <V2Article
                                        title={news_details.news_subject}
                                        // image={news_details.news_thumbnail.replace(" ", "")}
                                        author={news_details.username}
                                        create_at={news_details.updatedAt}
                                        update_at={news_details.approvedAt}
                                        body={news_details.news_body}
                                        uid={news_details.news_uid}
                                    />
                                    : null
                                : null
                        }
                    </Grid>

                    {/* <Grid
                        item
                        xs={12}
                    >
                        <ListNews
                            news={news_all.slice(0, 6)}
                        />
                    </Grid>
                    {
                        Array.from({ length: 6 }, (item, index) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                >
                                    {
                                        news_all !== undefined ?
                                            news_all.length > 0 ?
                                                <CardSmall
                                                    card_image={news_all[index + 6].news_thumbnail}
                                                    card_header={news_all[index + 6].news_subject}
                                                    chip_label={news_all[index + 6].category_name}
                                                    card_footer={news_all[index + 6].approvedAt}
                                                    target_id={news_all[index + 6].news_uid}
                                                    handleCardClick={() => handleCardClick(news_all[index + 6].news_uid)}
                                                />
                                                : null
                                            : null
                                    }
                                </Grid>
                            )
                        })
                    } */}
                </Grid>
            </Container>
        </>
    )
}