import React from 'react';
// import ReactDOM from 'react-dom/client';
import './index.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './pages/home-page';
import NewsListPage from './pages/news-list-page';
import NewsDetailsPage from './pages/news-details-page';
import LiveScorePage from './pages/livescore-page';
import AppHomePage from './pages/app-home-page';
import AppNewsDetailsPage from './pages/app-news-details-page';
import PrivacyPolicy from './pages/privacy-page';
import LivePage from './pages/live-page';
import V2AppNewsDetailsPage from './pages/v2-app-news-details-page';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/news" element={<NewsListPage />} />
        <Route path="/news/:uid" element={<NewsDetailsPage />} />
        <Route path="/live-score" element={<LiveScorePage />} />
        <Route path="/app/home" element={<AppHomePage />} />
        <Route path="/app/news/:uid" element={<AppNewsDetailsPage />} />
        <Route path="/v2/app/news/:uid" element={<V2AppNewsDetailsPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/live" element={<LivePage />} />        
      </Routes>
    </BrowserRouter>
  );
}

export default App;