import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Container,
} from '@mui/material';
import Article from '../../components/article';
import ListNews from '../../components/list/list-news';
import CardMiddle from '../../components/card/card-middle';
import Ad from '../../components/ad';
import V2Article from '../../components/v2-article';

export default function V2NewsDetailSMLayoutApp({ news_details, news_all }) {
    const handleCardClick = (uid) =>{
        if (uid === 0) {
            window.location = "/app/news"
        } else {
            window.location = "/app/news/" + uid
        }
    }
    return (
        <>
            <Container
                disableGutters={true}
                maxWidth="xl"
                sx={{
                    display: {
                        md: 'none',
                        xs: 'none',
                        sm: 'flex',
                    },
                    flexDirection: 'column',
                    columnGap: 2,
                    rowGap: 2,
                    pl: 2,
                    pr: 2,
                    pt: 2,
                    pb: 2
                }}
            >
                <Grid
                    direction="row"
                    container
                    spacing={2}
                    rowSpacing={2}
                >
                    <Grid
                        item
                        sm={12}
                    >
                        {
                            news_details !== undefined ?
                                news_details.news_thumbnail !== undefined ?
                                    <V2Article
                                        title={news_details.news_subject}
                                        author={news_details.username}
                                        create_at={news_details.updatedAt}
                                        update_at={news_details.approvedAt}
                                        body={news_details.news_body}
                                        uid={news_details.news_uid}
                                    />
                                    : null
                                : null
                        }
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}